import React, { Component } from "react";
import "../../../App.css";
import SuccessModal from "../../component/Modal/SuccessModal";
import {
  Button,
  Card,
  Dialog,
} from "@dsi/react-eds";
import { selected_location } from "../../utils/Constant";

class BookingView extends Component {
  constructor() {
    super();
    this.storage_selected_location = JSON.parse(
      localStorage.getItem(selected_location)
    );
    this.state = {
      confirmationModal: false,
      openDialogBox: false,
      errMsg: "",
    };

    this.onShow = this.onShow.bind(this);
  }
  onShow = () => {
    // this.setState({ confirmationModal: true });
    this.onClose();
  };

  onClose = (e, type) => {
    this.props._onBookDesk();
  };
 
  _getAssessmentView() {
    return (
      <Card title="">
        <h4 style={{ textAlign: "center" }}>Daily self-assessment </h4>
        <div style={{ textAlign: "center" }}>
          <p>When attending the office, you confirm that you have not </p>

          <p>
            tested positive or contacted with people tested positive for
            COVID-19 in the last 14 days.
          </p>

          <p>Also, in the past 24 hours you have not experienced</p>

          <p>
            Fever, aches and pain, sneezing, loss of taste, fatigue, runny or
            stuffy nose, headaches, cough, sore throat, diarrhea.
          </p>

          <p>
            <b>If any of above apply, stay at home and inform your manager.</b>
          </p>
        </div>
      </Card>
    );
  }
  
  _getAssessmentViewForUSLocation() {
    return (
      <Card title="">
        <h4 style={{ textAlign: "center" }}>Daily self-assessment </h4>
        <div className="self-assessment-container">
          <p>
            Have you tested positive for Covid-19 within the last 14 days?
            Yes/No
          </p>

          <p>
            Have you traveled outside of the U.S. within the past 14 days?
            Yes/No
          </p>

          <p>
            Do you have any of the following new or worsening symptoms or signs?
          </p>

          <p>
            Symptoms should not be chronic or related to other known causes or
            conditions.
          </p>
          <ul>
            <li>Fever or chills? Yes/No</li>
            <li>Difficulty breathing or shortness of breath? Yes/No</li>
            <li>Cough? Yes/No</li>
            <li>Sore throat, trouble swallowing? Yes/No</li>
            <li>Decrease or loss of smell or taste? Yes/No</li>
            <li>Nausea, vomiting, diarrhea, abdominal pain? Yes/No</li>
            <li>Extreme tiredness, sore muscles? Yes/No</li>
            <li>Runny Nose? Yes/No</li>
          </ul>
          <p>
            {" "}
            Have you been in close contact (within 6ft) with a confirmed or
            probable case of COVID-19 for 15 min or more in the last 14 days?
            Yes/No
          </p>
          <p>
            <b>
              If you answered 'yes' to any of these questions, please DO NOT
              enter the facility and notify your manager or Ericsson POC of the
              situation.
            </b>
          </p>
        </div>
      </Card>
    );
  }
  render() {
    const {
      selected_building_index,
      selected_floor_index,
      selected_region_index,
      user_location,
      default_you_are_booking_text,
      buildingList,
      floorList,
      regionList,
      isShowRoomBooked,
    } = this.props.props;

    const {
      onCloseSuccessBookModal,
    } = this.props;

    var zoneName = "";
    var seatCapacity = "";

    if (floorList[selected_floor_index].is_zone_available === "true") {
      zoneName = regionList[selected_region_index].name;
      seatCapacity =
        regionList[selected_region_index].seat_available +
        "/" +
        regionList[selected_region_index].seat_capacity;
    } else {
      zoneName = "";
      seatCapacity =
        floorList[selected_floor_index].seat_available +
        "/" +
        floorList[selected_floor_index].seat_capacity;
    }
    return (
      <div className="col-md-12">
        <div className="mt-1">
          <div className="row align-items-center justify-content-center welcome-text-div">
            <div>
              <label className="welcome-text-div-text-1">
                {default_you_are_booking_text + " " + user_location},
              </label>
            </div>
            <div className="welcome-text-div-location">
              <label className="welcome-text-div-text-2">
                {" "}
                &nbsp;{buildingList[selected_building_index].name},&nbsp;
                {floorList[selected_floor_index].suffix_value}
                ,&nbsp;{zoneName}
              </label>
            </div>
          </div>
        </div>

        <Card id="card-2" title={""}>
          <div className="row align-items-center justify-content-center mt-4 mb-1">
            <label className="text-justify fontWeight700 welcome-text-div-text-2">{`${seatCapacity}`}</label>
          </div>
        </Card>

        {this.storage_selected_location &&
        this.storage_selected_location.country_code === "US"
          ? this._getAssessmentViewForUSLocation()
          : this._getAssessmentView()}

        <div className=" row align-items-center justify-content-center mt-4 mb-3">
          <Button primary onClick={this.onShow}>
            {" "}
            {"BOOK A DESK"}
          </Button>
        </div>

        <div style={{ height: "40px" }}></div>

        <SuccessModal
          name={"Yes"}
          name2={"No"}
          isTwoButton={true}
          show={this.state.confirmationModal}
          message={"Please confirm your booking"}
          onClose={this.onClose}
        ></SuccessModal>

        <SuccessModal
          name={"Ok"}
          isTwoButton={false}
          show={isShowRoomBooked}
          message={"Booking successfully completed"}
          onClose={onCloseSuccessBookModal}
        ></SuccessModal>

        <Dialog
          title="Information"
          show={this.state.openDialogBox}
          onClose={() => this.setState({ openDialogBox: false })}
        >
          <p> {this.state.errMsg}</p>
        </Dialog>
      </div>
    );
  }
}

export default BookingView;
