import React from "react";

const PrivacyPolicy = (props) => {
  return (
    <div className="col-md-12">
      <p>
        <strong>Privacy Notice &ndash; Ericsson Desk Booking</strong>
      </p>
      <p>
        &nbsp;
        <br />{" "}
      </p>
      <p>
        <strong>General</strong>&nbsp;
      </p>
      <p>
        The scope of this notice is related to Ericsson processing personal data
        in the role of employer and the usage of Desk booking web
        application&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Who is collecting personal data?&nbsp;</strong>&nbsp;
      </p>
      <p>
        The personal data is being collected by the Controller, Ericsson AB,
        16480 Stockholm, Sweden. For more information, rectification, erasure,
        portability, or complaints to supervisory authorities about the
        processing of personal data please follow the link under 7 (Your rights)
        or contact an Ericsson Data Protection Officer or HR Direct.&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Why is personal data collected and processed?&nbsp;</strong>
        &nbsp;
      </p>
      <p>
        Your personal data is collected and processed as it is necessary for the
        functionality of the application.&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          What type of personal data is collected or processed?&nbsp;
        </strong>
        &nbsp;
      </p>
      <p>
        Personal data being processed includes, name, work address, work email
        address, IP-address with access logs, work photo, user ID and the
        reserved area/desk for the day.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Does </strong>
        <strong> Desk Booking </strong>
        <strong>collect sensitive personal data?&nbsp;</strong>&nbsp;
      </p>
      <p>
        In Desk booking application, the location of an individual is considered
        sensitive, for this purpose the information is only saved for a two
        weeks period to support if any incident have happened at that specific
        location such as tracing potential victims to COVID-19.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Why is personal data collected or processed? </strong>
      </p>
      <p>
        Desk booking is supporting social distancing which ensure a safer office
        environment in accordance with duty of care obligations from Ericsson to
        its employers.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          Where does the personal data that&rsquo;s being processed come
          from?&nbsp;
        </strong>
        &nbsp;
      </p>
      <p>
        The personal data originate from data subject, the Ericsson employee or
        consultant, general data are collected from other systems upon first
        time usage of the application &ndash; Ericsson Global Directory ( EGD
        ).&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Read more on privacy at Ericsson </strong>
        <a href="https://ericsson.sharepoint.com/sites/privacy_at_it_Classic">
          https://ericsson.sharepoint.com/sites/privacy_at_it_Classic
        </a>
        &nbsp;including your rights.
      </p>
      <p>&nbsp;</p>
    </div>
  );
};

export default PrivacyPolicy;
